<template>
  <div>
    <pinglun></pinglun>
  </div>
</template>

<script>
import pinglun from "../../../components/pinglun"
export default {
components:{
  pinglun
}
}
</script>

<style>

</style>